import { FOOTER_POSITIONS } from 'constants/footer';
import { useFooter } from 'features/cms/queries';
import Container from 'ui/Container/Container';
import { cn } from 'utils/cn';
import { getComponentsFromSlotPosition } from 'utils/footerUtil';
import CMSFooterLinkBar from './CMSFooterLinkBar/CMSFooterLinkBar';
import { StyledDesktopFooter, StyledFooterWrapper, StyledMobileTabletFooter } from './Footer.styled';
import FooterCompanyInfo from './FooterCompanyInfo/FooterCompanyInfo';
import FooterCompanyInfoBar from './FooterCompanyInfoBar/FooterCompanyInfoBar';
import FooterForm from './FooterForm/FooterForm';
import FooterHelpSection from './FooterHelpSection/FooterHelpSection';
import FooterSocialsBar from './FooterSocialsBar/FooterSocialsBar';

interface FooterProps {
  className?: string;
  minimal?: boolean;
  showOnlyCompanyInfo?: boolean;
  showPaymentMethods?: boolean;
}

const Footer = ({ className, minimal, showOnlyCompanyInfo, showPaymentMethods }: FooterProps) => {
  const { data: footerCMS } = useFooter();

  const helpData = getComponentsFromSlotPosition(footerCMS, FOOTER_POSITIONS.HELP)?.[0];
  const socialsData = getComponentsFromSlotPosition(footerCMS, FOOTER_POSITIONS.SOCIAL)?.[0];
  const paymentData = getComponentsFromSlotPosition(footerCMS, FOOTER_POSITIONS.PAYMENT)?.[0];
  const bottomData = getComponentsFromSlotPosition(footerCMS, FOOTER_POSITIONS.BOTTOM);

  const helpSlotLinks = helpData?.linkComponents;
  const helpSlotTitle = helpData?.title;

  const socialsSlotLinks = socialsData?.linkComponents;
  const paymentSlotMethods = showPaymentMethods ? paymentData?.paymentModes : undefined;

  if (showOnlyCompanyInfo) {
    return (
      <StyledFooterWrapper className={className}>
        <div className="bg-footer-socialsBar-background py-4" />
        <FooterCompanyInfo showAsRowOnDesktop />
      </StyledFooterWrapper>
    );
  }

  return (
    <Container className={cn('flex flex-col gap-8 border-t border-accent-40', className)} tag="footer">
      <FooterSocialsBar
        className="break-after break-before relative bg-footer-socialsBar-background before:bg-footer-socialsBar-background after:bg-footer-socialsBar-background"
        socialLinks={socialsSlotLinks}
      />

      <FooterHelpSection links={helpSlotLinks} title={helpSlotTitle} />

      {!minimal && (
        <StyledMobileTabletFooter className="container">
          <FooterForm />
        </StyledMobileTabletFooter>
      )}

      <div className="grid gap-8 lg:grid-cols-4">
        <div className={!minimal ? 'lg:col-span-3 lg:grid' : 'col-span-full'}>
          {!minimal && <CMSFooterLinkBar footerBottomSlot={bottomData} />}

          {paymentSlotMethods && <FooterCompanyInfoBar minimal={minimal} paymentMethods={paymentSlotMethods} />}
        </div>

        {!minimal && (
          <StyledDesktopFooter>
            <FooterForm />
          </StyledDesktopFooter>
        )}
      </div>
    </Container>
  );
};

export default Footer;
